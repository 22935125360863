import { Subject } from 'rxjs/Subject';
import { Api } from "./Api";
import { AccessRights } from './models';
import { Base64 } from "js-base64";
import { Logger } from './Logger';

export class AuthenticationService {
    private _subject: Subject<string>;
    private _accessRights: AccessRights;

    constructor() {
        this._subject = new Subject();
        this._accessRights = this.generateEmptyPermissions();
    }

    init() {
        var that = this;
        var lastLogin = localStorage.getItem("lastLogin");
        if (lastLogin) {
            var lastLoginDate = new Date(lastLogin);
            if (new Date().getTime() > (lastLoginDate.getTime() + 4 * 3600 * 1000)) { //add 2 hours expiration
                this._accessRights = new AccessRights();
                localStorage.removeItem('token');
                localStorage.removeItem('lastLogin');
                that.emit("UserUnknown");
            } else {
                Api.reLogin().then((res) => {
                    res.data.hasBasicAccess = true;
                    this._accessRights = res.data;
                    that.emit("UserLoggedIn");
                }).catch((err) => {
                    this._accessRights = this.generateEmptyPermissions();
                    that.emit("UserLoggedIn");
                });
            }
        } else {
            that.emit("UserUnknown");
        }
    }

    login(username: string, password: string, callback: () => void) {
        var that = this;
        Api.getAuthorizations(username, password).then((res) => {
            res.data.hasBasicAccess = true;
            res.data.isCustomer = false;
            this._accessRights = res.data;
            localStorage.setItem("token", Base64.encode(`${username}:${password}`));
            that.emit("UserLoggedIn");
        }).catch((err) => {
            this._accessRights = this.generateEmptyPermissions();
            that.emit("UserLoggedIn");
            Logger.LogErrorMessage("Ongeldige login");
            callback();
        });
    }

    customerLogin(customerCode: string, password: string, callback: () => void) {
        var that = this;
        Api.getAuthorizations(customerCode, password).then((res) => {
            res.data.isCustomer = true;
            this._accessRights = res.data;
            localStorage.setItem("token", Base64.encode(`${customerCode}:${password}`));
            that.emit("UserLoggedIn");

        }).catch((err) => {
            this._accessRights = this.generateEmptyPermissions();
            that.emit("UserLoggedIn");
            Logger.LogErrorMessage("Ongeldige login");
            callback();
        });
    }

    generateEmptyPermissions(): AccessRights {
        var newPermissions = new AccessRights();
        newPermissions.isAdmin = false;
        newPermissions.hasBasicAccess = false;
        return newPermissions;
    }

    emit(value: string) {
        this._subject.next(value);
    }

    asObservable() {
        return this._subject.asObservable();
    }

    isAuthenticated() {
        return this.getAccessRights().hasBasicAccess || this.getAccessRights().customerCode != "";
    }

    isAdministrator() {
        return this.getAccessRights().isAdmin;
    }

    getAccessRights(): AccessRights {
        if (this._accessRights == null)
            this._accessRights = this.generateEmptyPermissions();
        return this._accessRights;
    }

    logOut() {
        localStorage.removeItem('token');
        localStorage.removeItem('lastLogin');
        this.emit("UserLoggedOut");
    }
}
