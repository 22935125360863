import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Grid,
    PageSettingsModel, Sort, FilterSettingsModel, SortSettingsModel, SelectionSettingsModel, load 
} from '@syncfusion/ej2-react-grids';
import React from "react";
import styles from "./customerjobs.module.scss";
import { Api } from "../../Api";
import { ICustomerJob, UserCode } from "../../models";
import JobDetail from './jobDetail';
import Loader from '../../components/loader/component';
import _ from "lodash";

class CustomerJobs extends React.Component<ICustomerJobsProps, ICustomerJobsState> {
    private grid: Grid | null;

    constructor(props: ICustomerJobsProps) {
        super(props);

        this.grid = null;

        this.state = {
            loading: true,
            loadingJob: false,
            showAllJobs: props.isCustomer,
            allJobs: [],
            displayedJobs: [],
            selectedJob: null,
            pageSettings: { pageSize: 10 },
            sortSettings: {allowUnsort: false},
            filterSettings: {
                mode: "Immediate",
                immediateModeDelay: 200
            },
            selectionSettings: {
                mode: "Row"
            }
        };

        this.onClickShowAll = this.onClickShowAll.bind(this);
        this.onFinishJob = this.onFinishJob.bind(this);
    }

    componentDidMount() {
        this.loadJobs();
        this.loadUserCodes();
    }

    loadJobs() {
        var that = this;

        Api.getJobs().then((res: ICustomerJob[]) => {
            this.setState({
                loading: false,
                allJobs: res,
                selectedJob: null,
                displayedJobs: that.state.showAllJobs ? res : _.filter(res, { status: 1 })
            });
        });
    }

    loadUserCodes() {
        Api.getAllUserCodes().then((res: UserCode[]) => {
            sessionStorage.removeItem("usercodes");
            sessionStorage.setItem("usercodes", JSON.stringify(res));
        });
    }

    rowSelected = () => {
        var that = this;

        if (this.grid) {
            var selectedJob = this.grid.getSelectedRecords()[0] as ICustomerJob;
            that.setState({loadingJob: true});
            Api.getJob(selectedJob.id).then(res => {
                that.setState({
                    selectedJob: res,
                    loadingJob: false
                });
            });
        }
    };

    onClickShowAll(ev: React.ChangeEvent<HTMLInputElement>) {
        if (ev.currentTarget.checked) {
            this.setState({
                displayedJobs: this.state.allJobs,
                showAllJobs: true
            });
        } else {
            this.setState({
                displayedJobs: _.filter(this.state.allJobs, { status: 1 }),
                showAllJobs: false
            });
        }
    }

    onFinishJob() {
        this.loadJobs();
    }

    actionBegin(e: any) {
        if (e.requestType === 'filtering') {
            if (e.columns)
                e.columns.forEach((col: any) => {
                    col.operator = 'contains';
                });
        }
    }

    render() {
        return (
            <div className={styles.container}>
                {this.state.loading && <Loader />}
                {!this.state.loading && !this.props.isCustomer && !this.state.selectedJob && <div className={styles.showAllContainer}>
                    <label>Toon afgewerkte <input type="checkbox" defaultChecked={this.state.showAllJobs} onChange={this.onClickShowAll} /></label>
                </div>}
                {!this.state.loading && this.state.displayedJobs && this.state.displayedJobs.length > 0 && !this.state.selectedJob && 
                    <GridComponent ref={g => this.grid = g}
                    dataSource={this.state.displayedJobs} actionBegin={this.actionBegin}
                    selectionSettings={this.state.selectionSettings} rowSelected={this.rowSelected}
                    allowPaging={true} pageSettings={this.state.pageSettings}
                    allowSorting={true} sortSettings={this.state.sortSettings}
                    allowFiltering={true} filterSettings={this.state.filterSettings}>
                    <ColumnsDirective>
                        <ColumnDirective field='id' width='100' />
                        <ColumnDirective field='customer.name' width='100' />
                        <ColumnDirective field='userId' width='100' />
                        <ColumnDirective type="date" format="dd/MM/yyyy" field='timestamp' width='100' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Group]} />
                    </GridComponent>}

                {this.state.selectedJob && <a href="#" className={styles.backButton} onClick={() => this.setState({ selectedJob: null })}>⬅ Ga terug</a>}
                {this.state.selectedJob && <JobDetail job={this.state.selectedJob} onFinishCallback={this.onFinishJob} isCustomer={this.props.isCustomer} />}
            </div>
        );
    }
}

export default CustomerJobs;

export interface ICustomerJobsProps {
    isCustomer: boolean;
}

export interface ICustomerJobsState {
    loading: boolean;
    loadingJob: boolean;
    allJobs: ICustomerJob[];
    displayedJobs: ICustomerJob[];
    selectedJob: ICustomerJob | null;
    showAllJobs: boolean;

    pageSettings: PageSettingsModel;
    sortSettings: SortSettingsModel;
    filterSettings: FilterSettingsModel;
    selectionSettings: SelectionSettingsModel;
}