export class User {
    constructor() {
        this.id = "";
        this.email = "";
        this.isAdmin= false;
        this.isConsultant= false;
        this.isBlocked = false;
        this.creationDate = new Date();
        this.lastLoginDate = new Date();
        this.status = 0;
    }

    id: string;
    email: string;
    isAdmin: boolean;
    isConsultant: boolean;
    isBlocked: boolean;
    creationDate: Date;
    lastLoginDate: Date;
    status: number;
}