import "./header.css";
import React from "react";
import logo from "./logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router-dom";

class Header extends React.Component<IHeaderProps> {

    constructor(props: IHeaderProps) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    handleClick(e: React.MouseEvent<HTMLButtonElement>) {
        const CLASS_SHOW = 'show';
        const CLASS_COLLAPSE = 'collapse';
        const CLASS_COLLAPSING = 'collapsing';
        const CLASS_COLLAPSED = 'collapsed';
        const ANIMATION_TIME = 350; // 0.35s

        let el = e.currentTarget;
        let collapseTargetId = el.dataset.target || null;
        if (collapseTargetId) {
            let targetEl = document.querySelector(collapseTargetId) as HTMLDivElement;
            if (targetEl) {
                let isShown = targetEl.classList.contains(CLASS_SHOW) || targetEl.classList.contains(CLASS_COLLAPSING);
                if (!isShown) {
                    targetEl.classList.remove(CLASS_COLLAPSE);
                    targetEl.classList.add(CLASS_COLLAPSING);
                    targetEl.style.height = "0px";
                    targetEl.classList.remove(CLASS_COLLAPSED);
                    setTimeout(() => {
                        targetEl.classList.remove(CLASS_COLLAPSING);
                        targetEl.classList.add(CLASS_COLLAPSE, CLASS_SHOW);
                        targetEl.style.height = '';
                    }, ANIMATION_TIME)
                    targetEl.style.height = targetEl.scrollHeight + 'px';
                } else {
                    targetEl.style.height = `${targetEl.getBoundingClientRect().height}px`
                    targetEl.classList.add(CLASS_COLLAPSING);
                    targetEl.classList.remove(CLASS_COLLAPSE, CLASS_SHOW);
                    targetEl.style.height = '';
                    setTimeout(() => {
                        targetEl.classList.remove(CLASS_COLLAPSING);
                        targetEl.classList.add(CLASS_COLLAPSE);
                    }, ANIMATION_TIME)
                }
            }
        }
    }

    signOut(e: React.MouseEvent<HTMLLIElement>) {
        this.props.logOut();
        if (this.props.isCustomer == true)
            this.props.history.push("customerlogin");
        else
            this.props.history.push("login");
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light navbar-no-padding">
                <img src={logo} className="logo" alt="logo" />
                <button onClick={this.handleClick} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div id="navbar" className="collapse navbar-collapse">
                    <ul className="nav navbar-nav mx-auto">
                        {!this.props.isCustomer && <li className="nav-item active">
                            <NavLink activeClassName="active" to="/home" className="nav-link"><FontAwesomeIcon icon="home" /> Start<span className="sr-only">(current)</span></NavLink>
                        </li>}
                        {this.props.isAdmin && <li className="nav-item">
                            <NavLink activeClassName="active" to="/users" className="nav-link"><FontAwesomeIcon icon="user" /> Gebruikers<span className="sr-only">(current)</span></NavLink>
                        </li>}
                        {!this.props.isCustomer && <li className="nav-item">
                            <NavLink activeClassName="active" to="/usercodes" className="nav-link"><FontAwesomeIcon icon="list" /> Moederlijsten<span className="sr-only">(current)</span></NavLink>
                        </li>}
                        {!this.props.isCustomer && <li className="nav-item">
                            <NavLink activeClassName="active" to="/qr-codes" className="nav-link"><FontAwesomeIcon icon="qrcode" /> QR-codes<span className="sr-only">(current)</span></NavLink>
                        </li>}
                        {!this.props.isCustomer && <li className="nav-item">
                            <NavLink activeClassName="active" to="/customers" className="nav-link"><FontAwesomeIcon icon="user-friends" /> Klanten<span className="sr-only">(current)</span></NavLink>
                        </li>}
                        {!this.props.isCustomer && <li className="nav-item">
                            <NavLink activeClassName="active" to="/edit" className="nav-link"><FontAwesomeIcon icon="edit" /> Aanpassen<span className="sr-only">(current)</span></NavLink>
                        </li>}
                        <li className="nav-item">
                            <NavLink activeClassName="active" to="/jobs" className="nav-link"><FontAwesomeIcon icon="upload" /> Opdrachten<span className="sr-only">(current)</span></NavLink>
                        </li>
                        <li className="nav-item right-menu" onClick={this.signOut}>
                            <button className="nav-link buttonLink"><FontAwesomeIcon icon="power-off" /> Afmelden</button>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default withRouter(Header);

export interface IHeaderProps extends RouteComponentProps {
    isAdmin: boolean;
    isCustomer?: boolean;
    logOut: () => void;
}