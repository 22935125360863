import React from "react";
import { UserCode } from "../../models";

class UsercodeDisplay extends React.Component<IUsercodeDisplayProps> {
    render() {
        var allCodes = sessionStorage.getItem("usercodes");
        if (allCodes == null)
            return "";

        var parsedCodes = JSON.parse(allCodes) as UserCode[];
        var match = parsedCodes.find((code) => {
            return code.groupCode === this.props.groupCode && code.code === this.props.code;
        });

        return (
            <span>{match?.description}</span>
        );
    }
}

export default UsercodeDisplay;

export interface IUsercodeDisplayProps {
    code: string;
    groupCode: string;
}