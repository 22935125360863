export class Location {
    constructor() {
        this.id = 0;
        this.name = "";
        this.expanded = "";
        this.subChild = null;
        this.selected = false;
        this.contact = "";
        this.contactPhone = "";
        this.remarks = "";
        this.parentId = 0;
        this.showOnReport = false;
    }


    id: number;
    name: string;
    expanded: string;
    subChild: Location[] | null;
    selected: boolean;
    contact: string;
    contactPhone: string;
    remarks: string;
    parentId: number;
    showOnReport: boolean;
}