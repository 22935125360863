import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Grid,
    PageSettingsModel, Sort, FilterSettingsModel, SortSettingsModel, SelectionSettingsModel 
} from '@syncfusion/ej2-react-grids';
import React from "react";
import styles from "./customers.module.scss";
import { Api } from "../../Api";
import { ICustomer, Device, UserCode } from "../../models";
import LocationList from '../../components/locationList/location-list';
import DeviceList from '../../components/deviceList/device-list';
import DeviceDialog from '../../components/deviceDialog/deviceDialog';
import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logger } from '../../Logger';
import CustomerEditNameDialog from '../../components/customerEditNameDialog/customerEditNameDialog';
import CustomerAddDialog from '../../components/customerAddDialog/customerAddDialog';
import LocationAddDialog from '../../components/locationAddDialog/locationAddDialog';
import LocationContact from '../../components/locationContact/location-contact';
import { NavLink, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import _ from "lodash";
import Loader from '../../components/loader/component';

class Customers extends React.Component<RouteComponentProps<CustomRouteProps>, ICustomersState> {
    private grid: Grid | null;
    private newLocation: InPlaceEditorComponent | null = null;
    private customerCode: InPlaceEditorComponent | null = null;
    private customerSecret: InPlaceEditorComponent | null = null;

    constructor(props: RouteComponentProps<CustomRouteProps>) {
        super(props);

        this.grid = null;

        this.state = {
            loading: true,
            showAddCustomerPopup: false,
            showAddLocationPopup: false,
            showEditNamePopup: false,
            customers: [],
            selectedLocation: null,
            selectedCustomer: null,
            selectedDevice: null,
            pageSettings: { pageSize: 10 },
            sortSettings: {allowUnsort: false},
            filterSettings: {
                mode: "Immediate",
                immediateModeDelay: 200
            },
            selectionSettings: {
                mode: "Row"
            }
        };

        this.onAddCustomer = this.onAddCustomer.bind(this);
        this.onAddLocation = this.onAddLocation.bind(this);
        this.onSaveCustomerLogin = this.onSaveCustomerLogin.bind(this);
        this.onDownloadList = this.onDownloadList.bind(this);
        this.onDownloadListNew = this.onDownloadListNew.bind(this);
    }

    componentDidMount() {
        this.loadCustomers();
        this.loadUserCodes();
    }

    loadCustomers() {
        Api.getCustomers().then((res: ICustomer[]) => {
            let customer: ICustomer | null = null;
            if (this.props.match.params.id != null) {
                let result = _.find(res, (r) => r.id.toString() == this.props.match.params.id);
                if (result !== undefined) {
                    customer = result;
                }
            }

            this.setState({
                loading: false,
                customers: res,
                selectedCustomer: customer
            });
        });
    }

    loadUserCodes() {
        Api.getAllUserCodes().then((res: UserCode[]) => {
            sessionStorage.removeItem("usercodes");
            sessionStorage.setItem("usercodes", JSON.stringify(res));
        });
    }

    rowSelected = () => {
        if (this.grid) {
            var selected = this.grid.getSelectedRecords()[0] as ICustomer;
            this.setState({
                selectedCustomer: selected,
                selectedLocation: null
            });
            this.props.history.push("/customers/" + selected.id);

        }
    };

    onAddCustomer() {
        this.setState({ showAddCustomerPopup: true });
    }

    onDownloadList() {
        if (this.state.selectedCustomer && this.state.selectedLocation) {
            this.setState({ loading: true });
            Api.downloadList(this.state.selectedCustomer.id, this.state.selectedLocation).then(resp => {
                this.setState({ loading: false });
            });
        }
    }

    onDownloadListNew() {
        if (this.state.selectedCustomer && this.state.selectedLocation) {
            this.setState({ loading: true });
            Api.downloadListNew(this.state.selectedCustomer.id, this.state.selectedLocation).then(resp => {
                this.setState({ loading: false });
            });
        }
    }

    onSaveCustomerLogin() {
        var code = "";
        var secret = "";

        if (this.customerCode?.value != null) { code = this.customerCode.value.toString() };
        if (this.customerSecret?.value != null) { secret = this.customerSecret.value.toString() };

        if (code != "" && secret != "" && this.state.selectedCustomer != null) {
            //update
            this.setState({ loading: true });
            Api.updateCompanyCodes(this.state.selectedCustomer.id, code, secret).then(resp => {
                this.setState({ loading: false });
            });

        } else {
            Logger.LogWarning("Kies geldige codes");
        }
    }

    onAddLocation() {
        this.setState({ showAddLocationPopup: true });
    }

    locationSelected = (ev: any) => {
        this.setState({ selectedLocation: parseInt(ev.nodeData.id) });
    };

    deviceSelected = (ev: any) => {
        this.setState({ selectedDevice: ev.data as Device });
    };

    deviceClosed = (ev: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ selectedDevice: null });
    };

    addClosed = () => {
        this.setState({ showAddCustomerPopup: false, showAddLocationPopup: false });
    };

    customerNameClosed = (name: string) => {
        if (this.state.selectedCustomer && name !== "") {
            this.setState((prevState) => ({
                selectedCustomer: {
                    ...prevState.selectedCustomer!,
                    name: name,
                },
                showEditNamePopup: false
            }));
        };
    };

    actionBegin(e: any) {
        if (e.requestType === 'filtering') {
            if (e.columns)
                e.columns.forEach((col: any) => {
                    col.operator = 'contains';
                });
        }
    }

    onClickEditName = () => {
        this.setState({ showEditNamePopup: true });
    }

    render() {
        return (
            <div className={styles.container}>
                {!this.state.selectedCustomer && <button type="button" className="btn btn-md btn-primary" onClick={this.onAddCustomer}><FontAwesomeIcon icon="plus-circle" /> Nieuwe klant</button>}
                {!this.state.selectedCustomer && <h1>Klanten</h1>}
                {this.state.loading && <Loader />}
                {!this.state.loading && this.state.customers && this.state.customers.length > 0 && !this.state.selectedCustomer &&
                    <GridComponent ref={g => this.grid = g}
                    dataSource={this.state.customers} actionBegin={this.actionBegin}
                    selectionSettings={this.state.selectionSettings} rowSelected={this.rowSelected}
                    allowPaging={true} pageSettings={this.state.pageSettings}
                    allowSorting={true} sortSettings={this.state.sortSettings}
                    allowFiltering={true} filterSettings={this.state.filterSettings}>
                    <ColumnsDirective>
                        <ColumnDirective field='id' width='100' />
                        <ColumnDirective field='name' width='100' />
                        <ColumnDirective field='active' width='100' />
                    </ColumnsDirective>
                    <Inject services={[Page, Sort, Filter, Group]} />
                    </GridComponent>}

                {this.state.selectedCustomer && <a href="#" className={styles.backButton} onClick={() => { this.props.history.goBack(); this.setState({ selectedCustomer: null }); }}>⬅ Ga terug</a>}

                {!this.state.loading && <Route path="/:id" render={({ match }) => {
                    return <div className={styles.bottom}>
                        {this.state.selectedCustomer && <div className={styles.locationSelect}>
                            <h2>{this.state.selectedCustomer.name} <span style={{ fontSize: '0.5em', cursor: 'pointer' }} onClick={this.onClickEditName}><FontAwesomeIcon icon="pencil-alt" /></span> </h2>
                            <label className="control-label">Klantcode</label>
                            <InPlaceEditorComponent
                                mode='Inline' type='Text' value={this.state.selectedCustomer.companyCode}
                                ref={(e) => { this.customerCode = e }}
                                emptyText=""
                                model={{ placeholder: 'Klantcode' }}
                                popupSettings={{ title: 'Klantcode' }}>
                            </InPlaceEditorComponent><br />
                            <label className="control-label">klantwachtwoord</label>
                            <InPlaceEditorComponent
                                mode='Inline' type='Text' value={this.state.selectedCustomer.companySecret}
                                ref={(e) => { this.customerSecret = e }}
                                emptyText=""
                                model={{ placeholder: 'klantwachtwoord' }}
                                popupSettings={{ title: 'klantwachtwoord' }}>
                            </InPlaceEditorComponent><br />
                            <button type="button" className="btn btn-xs btn-primary" onClick={this.onSaveCustomerLogin}>Opslaan</button>


                            <h1>Locaties</h1>
                            <div>
                                <button type="button" className="btn btn-xs btn-primary" onClick={this.onAddLocation}><FontAwesomeIcon icon="plus-circle" /> Nieuwe locatie</button>
                            </div>

                            <LocationList customerId={this.state.selectedCustomer.id} locationSelected={this.locationSelected} />
                        </div>}
                        {this.state.selectedLocation && this.state.selectedCustomer && <div className={styles.deviceSelect}>
                            <div className={styles.rightPane}>
                                {this.state.selectedLocation > 0 && <button type="button" className="btn btn-xs btn-primary" onClick={this.onDownloadList}><FontAwesomeIcon icon="download" /> Download lijst</button>}
                                {this.state.selectedLocation > 0 && <button type="button" className="btn btn-xs btn-primary" style={{ marginLeft: 10 }} onClick={this.onDownloadListNew}><FontAwesomeIcon icon="download" /> Download lijst (nieuwe layout)</button>}
                                {this.state.selectedLocation > 0 && <LocationContact locationId={this.state.selectedLocation} />}
                                <h1>Toestellen</h1>
                                <DeviceList customerId={this.state.selectedCustomer.id} locationId={this.state.selectedLocation} deviceSelected={this.deviceSelected} />
                            </div>
                        </div>}
                    </div>
                }} />}
                {this.state.showEditNamePopup && this.state.selectedCustomer && <CustomerEditNameDialog modalClosed={this.customerNameClosed} name={this.state.selectedCustomer.name} id={this.state.selectedCustomer.id} />}
                {this.state.showAddCustomerPopup && <CustomerAddDialog modalClosed={this.addClosed} />}
                {this.state.showAddLocationPopup && this.state.selectedCustomer && <LocationAddDialog customerId={this.state.selectedCustomer.id} modalClosed={this.addClosed} />}
                {this.state.selectedDevice !== null && <DeviceDialog device={this.state.selectedDevice} modalClosed={this.deviceClosed} />}
            </div>
        );
    }
}

export default withRouter(Customers);

export interface CustomRouteProps {
    id?: string | undefined;
}

export interface ICustomersState {
    loading: boolean;
    showAddCustomerPopup: boolean;
    showEditNamePopup: boolean;
    showAddLocationPopup: boolean;
    customers: ICustomer[];
    selectedCustomer: ICustomer | null ;
    selectedLocation: number | null;
    selectedDevice: Device | null;

    pageSettings: PageSettingsModel;
    sortSettings: SortSettingsModel;
    filterSettings: FilterSettingsModel;
    selectionSettings: SelectionSettingsModel;
}