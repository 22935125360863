import React from "react";
import { Api } from "../../Api";
import { Device } from "../../models";
import Loader from "../loader/component";
import styles from "./styles.module.scss";
import fe from "../deviceDisplay/fe.svg";
import Moment from "react-moment";

class PublicDetail extends React.Component<IPublicDetailProps, IPublicDetailState> {

    constructor(props: IPublicDetailProps) {
        super(props);

        this.state = {
            loading: true,
            device: null
        };
    }

    componentDidMount() {
        this.loadDevice(this.props.code);
    }

    loadDevice(code: string) {
        Api.getDevicePublicData(code).then((res: Device) => {
            this.setState({
                loading: false,
                device: res
            });
        });
    }

    render() {
        let imageSrc = "";
        switch (this.state.device?.typeCode) {
            case "BRAND":
                imageSrc = fe;
                break;
            default:
                imageSrc = "";
                break;
        }

        return (
            <div>
                {this.state.loading && <Loader />}
                {!this.state.loading && this.state.device != null && <div>
                    <div className={styles.typeImage}><img src={imageSrc} alt="type" /></div>
                    <div className={styles.content + " form-group"}>
                        <label>Benor nummer</label>
                        <small className="form-text text-muted">{this.state.device.benorNr}</small>
                    </div>
                    <div className={styles.content + " form-group"}>
                        <label>Intern nummer</label>
                        <small className="form-text text-muted">{this.state.device.internalNumber}</small>
                    </div>
                    <div className={styles.content + " form-group"}>
                        <label>Tijdstip laatste keuring</label>
                        <small className="form-text text-muted">
                            <Moment format="DD/MM/YYYY">{this.state.device.timestamp}</Moment>
                        </small>
                    </div>
                    <div className={styles.content + " form-group"}>
                        <label>Volgende keuring</label>
                        <small className="form-text text-muted">
                            <Moment format="DD/MM/YYYY">{this.state.device.nextInspectionDate}</Moment>
                        </small>
                    </div>
                </div>}
            </div>
        );
    }
}

export default PublicDetail;

export interface IPublicDetailProps {
    code: string;
}

export interface IPublicDetailState {
    loading: boolean;
    device: Device | null;
}