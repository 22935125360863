import React from "react";
import Moment from "react-moment";
import { ICustomerJob } from "../../models";
import DeviceList from "./deviceList";
import InspectionList from "./inspectionList";
import styles from "./customerjobs.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Api } from "../../Api";
import { Logger } from "../../Logger";
import _ from "lodash";

class JobDetail extends React.Component<IJobDetailProps, IJobDetailState> {

    constructor(props: IJobDetailProps) {
        super(props);

        this.onFinishJob = this.onFinishJob.bind(this);

        var total = 0;
        var totalOK = 0;
        var totalNOK = 0;
        var totalNew = props.job.newDevices.length;

        _.each(props.job.inspections, (i) => {
            _.each(i.fireExtinguishers, (fe) => {
                total++;
                if (fe.statusCode === "AFGEK")
                    totalNOK++;
                else
                    totalOK++;
            });
        });

        this.state = {
            total: total,
            totalOK: totalOK,
            totalNew: totalNew,
            totalNOK: totalNOK
        };
    }

    onFinishJob() {
        Api.FinishJob(this.props.job.id).then(res => {
            Logger.LogSuccess("Job afgesloten");
            this.props.onFinishCallback();
        });
    }

    render() {
        return (
            <div>
                <h3>Overzicht opdracht ({this.props.job.id})</h3>
                {this.props.isCustomer == false && this.props.job.status == 1 && <div><button type="button" className="btn btn-xs btn-success" onClick={this.onFinishJob}><FontAwesomeIcon icon="save" /> Markeer afgehandeld</button><br /> <br /></div>}

                <span><b>Gemaakt door: </b>{this.props.job.userId}</span><br />
                <span><b>Tijdstip: </b><Moment format="DD/MM/YYYY HH:mm">{this.props.job.timestamp}</Moment></span><br />
                <span><b>Klant: </b>{this.props.job.customer.name}</span><br />
                <span><b>Getekend door: </b>{this.props.job.signedBy}</span><br />
                <span><b>Getekend op: </b><Moment format="DD/MM/YYYY HH:mm">{this.props.job.signatureDate}</Moment></span><br />
                <span><b>Handtekening: </b><img className={styles.signature} src={"/api/image/signature/" + this.props.job.id} /></span><br />

                <table className={styles.tableResults}>
                    <thead>
                        <tr>
                            <th>Aantal gekeurd</th>
                            <th>Goedgekeurd</th>
                            <th>Afgekeurd</th>
                            <th># Nieuwe toestellen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.state.total}</td>
                            <td>{this.state.totalOK}</td>
                            <td>{this.state.totalNOK}</td>
                            <td>{this.state.totalNew}</td>
                        </tr>
                    </tbody>
                </table>

                <InspectionList inspections={this.props.job.inspections} />

                <h3>Nieuwe toestellen</h3>
                <DeviceList devices={this.props.job.newDevices} />
            </div>
        );
    }
}

export default JobDetail;

export interface IJobDetailProps {
    onFinishCallback: () => void;
    job: ICustomerJob;
    isCustomer?: boolean;
}

export interface IJobDetailState {
    total: number;
    totalOK: number;
    totalNOK: number;
    totalNew: number;
}