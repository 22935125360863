export class AccessRights {
    constructor() {
        this.isAdmin = false;
        this.isCustomer = false;
        this.hasBasicAccess = false;
        this.email = "";
        this.customerCode = "";
    }

    public isAdmin: boolean;
    public isCustomer: boolean;
    public hasBasicAccess: boolean;
    public email: string;
    public customerCode: string;
    public id: any;
}