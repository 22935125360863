import React from "react";
import Moment from "react-moment";
import UsercodeDisplay from "../../components/usercodeDisplay/component";
import { Device } from "../../models";
import styles from "./customerjobs.module.scss";

class DeviceDetail extends React.Component<IDeviceDetailProps, IDeviceDetailState> {

    constructor(props: IDeviceDetailProps) {
        super(props);

        this.state = {
            showImages: false
        };
    }

    render() {
        return (
            <div>
                <div className={this.props.device.statusCode == "AFGEK" ? styles.highlight : ""}>
                    <div>Benor: {this.props.device.benorNr} / Locatie: {this.props.device.c_Location} /
                        <UsercodeDisplay code={this.props.device.statusCode} groupCode="FESTA" /> {this.props.device.causeRejectionCode && <span> / <UsercodeDisplay code={this.props.device.causeRejectionCode} groupCode="FECRE" /></span>}
                    </div>
                </div>
                <div>
                    <div>
                        <ul>
                            <li>Type: <UsercodeDisplay code={this.props.device.c_TypeCode} groupCode="FETYP" /></li>
                            <li>Merk: <UsercodeDisplay code={this.props.device.brandCode} groupCode="FEBRA" /></li>
                            <li>Intern nummer: {this.props.device.internalNumber}</li>
                            <li>Jaartal: {this.props.device.year}</li>
                            <li>Gewicht: {this.props.device.weight}</li>
                            <li>Tijdstip keuren: <Moment format="DD/MM/YYYY HH:MM">{this.props.device.timestamp}</Moment></li>
                            <li>Volgende inspectie: <Moment format="DD/MM/YYYY HH:MM">{this.props.device.nextInspectionDate}</Moment></li>
                            <li>Status: <UsercodeDisplay code={this.props.device.statusCode} groupCode="FESTA" /></li>
                            <li>Reden afkeuren: <UsercodeDisplay code={this.props.device.causeRejectionCode} groupCode="FECRE" /></li>
                            <li>Opmerkingen: {this.props.device.remarks}</li>
                            <li>
                                Onderdelen:
                                <ul>
                                    {this.props.device.parts.map(function (part: string) {
                                        return <li key={part}><UsercodeDisplay code={part} groupCode="FESOD" /></li>
                                    })}
                                </ul>
                            </li>

                            {this.props.device.images && !this.state.showImages && <li><a href="#" className={styles.showImages} onClick={() => this.setState({ showImages: true })}>Foto's laden</a></li>}
                            {this.props.device.images && this.state.showImages && <li>
                                {this.props.device.images.split(",").map(function (item: string) {
                                    return <div key={item}><img className={styles.signature} src={"/api/image/device/" + item} /></div>
                                })}
                            </li>}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeviceDetail;

export interface IDeviceDetailProps {
    device: Device;
}

export interface IDeviceDetailState {
    showImages: boolean;
}