import React from "react";
import { FireExtinguisher, Device, UserCode, ICustomer } from "../../models";
import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles.module.scss";
import { Api } from "../../Api";
import _ from "lodash";
import LocationList from "../locationList/location-list";
import { Loader } from "rsuite";
import { Logger } from "../../Logger";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns/src/drop-down-list/dropdownlist.component";

class DeviceEdit extends React.Component<IDeviceEditProps, IDeviceEditState> {
    private benorNr: InPlaceEditorComponent | null = null;
    private internalNumber: InPlaceEditorComponent | null = null;
    private typeCode: InPlaceEditorComponent | null = null;
    private brandCode: InPlaceEditorComponent | null = null;
    private year: InPlaceEditorComponent | null = null;
    private weight: InPlaceEditorComponent | null = null;
    private location: InPlaceEditorComponent | null = null;
    private remarks: InPlaceEditorComponent | null = null;
    private modificationDate: InPlaceEditorComponent | null = null;
    private status: InPlaceEditorComponent | null = null;
    private causeRejection: InPlaceEditorComponent | null = null;
    private customerDropdown: DropDownListComponent | null = null;
    private customerDropdownFields: object = { text: 'Customer.name', value: 'Customer.id' };

    constructor(props: IDeviceEditProps) {
        super(props);

        this.state = {
            customers: null,
            loading: true,
            device: Object.assign({}, props.device),
            selectedLocation: null,
            typeCodes: [],
            brandCodes: [],
            statusCodes: [],
            rejectionCodes: []
        };

        this.onSave = this.onSave.bind(this);
        this.onChangeCustomer = this.onChangeCustomer.bind(this);
    }

    componentDidMount() {
        this.loadUserCodes();
        if(this.props.device.id === 0)
            this.loadCustomers();
    }

    componentDidUpdate(prevProps) {
        if (this.props.device.id !== prevProps.device.id) {
            this.setState({
                customers: null,
                loading: false,
                device: Object.assign({}, this.props.device),
                selectedLocation: null,
                typeCodes: [],
                brandCodes: [],
                statusCodes: [],
                rejectionCodes: []
            });
            if (this.props.device.id === 0)
                this.loadCustomers();
            this.loadUserCodes();
        }
    }

    loadUserCodes() {
        Api.getAllUserCodes().then(res => {
            this.setState({
                typeCodes: _.filter(res, { groupCode: "FETYP" }),
                brandCodes: _.filter(res, { groupCode: "FEBRA" }),
                statusCodes: _.filter(res, { groupCode: "FESTA" }),
                rejectionCodes: _.filter(res, { groupCode: "FECRE" }),
                loading: false
            });
        });
    }

    loadCustomers() {
        var that = this;
        Api.getCustomers().then((res: ICustomer[]) => {
            var list: any = [];
            res.forEach(r => {
                list.push({ Customer: r });
            });
            that.setState({
                customers: list
            });
        });
    }

    locationSelected = (ev: any) => {
        this.setState({ selectedLocation: parseInt(ev.nodeData.id) });
    };

    onSave() {
        var that = this;
        this.setState({ loading: true });

        var newFe = new FireExtinguisher();
        newFe.ID = this.props.device.id;
        if (this.modificationDate?.value != null) { newFe.timestamp = new Date(this.modificationDate.value.toString()) };
        if (this.benorNr?.value != null) { newFe.benorNr = this.benorNr.value.toString() };
        if (this.internalNumber?.value != null) { newFe.internalNumber = this.internalNumber.value.toString() };
        if (this.typeCode?.value != null) { newFe.c_TypeCode = this.typeCode.value.toString() };
        if (this.brandCode?.value != null) { newFe.brandCode = this.brandCode.value.toString() };
        if (this.year?.value != null) { newFe.year = parseInt(this.year.value.toString()) };
        if (this.weight?.value != null) { newFe.weight = parseFloat(this.weight.value.toString()) };
        if (this.location?.value != null) { newFe.c_Location = this.location.value.toString() };
        if (this.remarks?.value != null) { newFe.remarks = this.remarks.value.toString() };
        if (this.status?.value != null) { newFe.statusCode = this.status.value.toString() };
        if (this.causeRejection?.value != null) { newFe.causeRejectionCode = this.causeRejection.value.toString() };
        if (this.state.selectedLocation != null) { newFe.locationId = this.state.selectedLocation };

        Api.updateOrAddFireExtinguisher(newFe).then(res => {
            Logger.LogSuccess("Opgeslagen");

            if (that.props.device.id === 0) {
                if (this.modificationDate?.value != null) { this.modificationDate.value = new Date() };
                if (this.benorNr?.value != null) { this.benorNr.value = "" };
                if (this.internalNumber?.value != null) { this.internalNumber.value = "" };
                if (this.typeCode?.value != null) { this.typeCode.value = "" };
                if (this.brandCode?.value != null) { this.brandCode.value = "" };
                if (this.year?.value != null) { this.year.value = 0 };
                if (this.weight?.value != null) { this.weight.value = 0 };
                if (this.location?.value != null) { this.location.value = "" };
                if (this.remarks?.value != null) { this.remarks.value = "" };
                if (this.status?.value != null) { this.status.value = "" };
                if (this.causeRejection?.value != null) { this.causeRejection.value = "" };
            } else {
                //update props now
                this.props.device.timestamp = newFe.timestamp;
                this.props.device.benorNr = newFe.benorNr;
                this.props.device.internalNumber = newFe.internalNumber;
                this.props.device.c_TypeCode = newFe.c_TypeCode;
                this.props.device.brandCode = newFe.brandCode;
                this.props.device.year = newFe.year;
                this.props.device.weight = newFe.weight;
                this.props.device.c_Location = newFe.c_Location;
                this.props.device.remarks = newFe.remarks;
                this.props.device.statusCode = newFe.statusCode;
                this.props.device.causeRejectionCode = newFe.causeRejectionCode;
                this.props.device.locationId = newFe.locationId;
            }
        }).catch(er => {
            Logger.LogErrorMessage(er.response.data);
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    public onChangeCustomer(args: any) {
        if (args.isInteracted && this.customerDropdown != null) {
            var device = this.state.device;
            device.customerId = parseInt(this.customerDropdown.value.toString());
            this.setState({
                device: device
            });
        }
    }

    render() {
        return (
            <div>
                <h3>{this.props.device.id === 0 ? "Toevoegen" : "Wijzigen"}</h3>
                <table className={styles.editTable}>
                    <tbody>
                        {this.props.device.id === 0 && this.state.customers != null && <tr>
                            <td><label className="control-label">Klant</label></td>
                            <td>
                                <DropDownListComponent
                                    ref={(e) => { this.customerDropdown = e }}
                                    fields={this.customerDropdownFields}
                                    id="ddlelement" dataSource={this.state.customers}
                                    change={this.onChangeCustomer}
                                    placeholder="Kies een klant" />
                            </td>
                        </tr>}
                        <tr>
                            <td><label className="control-label">Datum wijziging</label></td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='Date' value={this.props.device.timestamp}
                                    ref={(e) => { this.modificationDate = e }}
                                    emptyText=""
                                    model={{ placeholder: 'Datum wijziging', format: 'dd-MM-yyyy' }}
                                    popupSettings={{ title: 'Datum wijziging' }}>
                                </InPlaceEditorComponent>
                            </td>
                        </tr>
                        <tr>
                            <td><label className="control-label">Benor nummer</label></td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='Text' value={this.props.device.benorNr}
                                    ref={(e) => { this.benorNr = e }}
                                    emptyText=""
                                    model={{ placeholder: 'Benor nummer' }}
                                    popupSettings={{ title: 'Benor nummer' }}>
                                </InPlaceEditorComponent>
                            </td>
                            <td><label className="control-label">Intern nummer</label></td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='Text' value={this.props.device.internalNumber ? this.props.device.internalNumber : ""}
                                    ref={(e) => { this.internalNumber = e }}
                                    emptyText=""
                                    model={{ placeholder: 'Intern nummer' }}
                                    popupSettings={{ title: 'Intern nummer' }}>
                                </InPlaceEditorComponent>
                            </td>
                        </tr>
                        <tr>
                            <td><label className="control-label">Type toestel</label></td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='DropDownList' value={this.props.device.c_TypeCode} 
                                    emptyText=""
                                    ref={(e) => { this.typeCode = e }} cssClass={styles.dropdown}
                                    model={{ dataSource: this.state.typeCodes, fields: { text: "description", value: "code" }, placeholder: 'Kies een type' }}>
                                </InPlaceEditorComponent>
                            </td>
                            <td><label className="control-label">Merk toestel</label>
                            </td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='DropDownList' value={this.props.device.brandCode}
                                    emptyText=""
                                    ref={(e) => { this.brandCode = e }} cssClass={styles.dropdown}
                                    model={{ dataSource: this.state.brandCodes, fields: { text: "description", value: "code" }, placeholder: 'Kies een merk' }}>
                                </InPlaceEditorComponent>
                            </td>
                        </tr>
                        <tr>
                            <td><label className="control-label">Fabricagejaar</label></td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='Numeric' value={this.props.device.year}
                                    ref={(e) => { this.year = e }}
                                    emptyText=""
                                    model={{ placeholder: 'Jaar', format: "####" }}
                                    popupSettings={{ title: 'Jaar' }}>
                                </InPlaceEditorComponent>
                            </td>
                            <td><label className="control-label">Gewicht (Kg)</label></td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='Numeric' value={this.props.device.weight}
                                    ref={(e) => { this.weight = e }}
                                    emptyText=""
                                    model={{ placeholder: 'Gewicht', format: "###.##", step:0.01 }}
                                    popupSettings={{ title: 'Gewicht' }}>
                                </InPlaceEditorComponent>
                            </td>
                        </tr>
                        <tr>
                            <td><label className="control-label">Locatie</label></td>
                            <td colSpan={3}>
                                <InPlaceEditorComponent
                                    mode='Inline' type='Text' value={this.props.device.c_Location}
                                    ref={(e) => { this.location = e }}
                                    emptyText="" cssClass={styles.bigText}
                                    model={{ placeholder: 'Locatie' }}
                                    popupSettings={{ title: 'Locatie' }}>
                                </InPlaceEditorComponent>
                            </td>
                        </tr>
                        <tr>
                            <td><label className="control-label">Opmerkingen</label></td>
                            <td colSpan={3}>
                                <InPlaceEditorComponent
                                    mode='Inline' type='Text' value={this.props.device.remarks}
                                    ref={(e) => { this.remarks = e }}
                                    emptyText="" cssClass={styles.bigText}
                                    model={{ placeholder: 'Opmerkingen' }}
                                    popupSettings={{ title: 'Opmerkingen' }}>
                                </InPlaceEditorComponent>
                            </td>
                        </tr>
                        <tr>
                            <td><label className="control-label">Status nazicht</label></td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='DropDownList' value={this.props.device.statusCode}
                                    emptyText=""
                                    ref={(e) => { this.status = e }} cssClass={styles.dropdown} 
                                    model={{ dataSource: this.state.statusCodes, fields: { text: "description", value: "code" }, placeholder: 'Kies een status' }}>
                                </InPlaceEditorComponent>
                            </td>
                            <td><label className="control-label">Reden afkeuren</label></td>
                            <td>
                                <InPlaceEditorComponent
                                    mode='Inline' type='DropDownList' value={this.props.device.causeRejectionCode}
                                    ref={(e) => { this.causeRejection = e }} cssClass={styles.dropdown}
                                    emptyText=""
                                    model={{ dataSource: this.state.rejectionCodes, fields: { text: "description", value: "code" }, placeholder: 'Kies een reden' }}>
                                </InPlaceEditorComponent>
                            </td>
                        </tr>
                        <tr>
                            <td><label className="control-label">Locatie</label></td>
                            <td colSpan={3}>
                                <LocationList customerId={this.state.device.customerId} locationSelected={this.locationSelected} selectedLocation={this.props.device.locationId} />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {!this.state.loading && <button type="button" className="btn btn-success" onClick={this.onSave}><FontAwesomeIcon icon="save" /> Opslaan</button>}
                {this.state.loading && <Loader />}
            </div>
        );
    }
}

export default DeviceEdit;

export interface IDeviceEditProps {
    device: Device;
}

export interface IDeviceEditState {
    loading: boolean;
    device: Device;
    customers: { [key: string]: Object } [] | null;
    selectedLocation: number | null;

    typeCodes: any[];
    brandCodes: any[];
    statusCodes: any[];
    rejectionCodes: any[];
}