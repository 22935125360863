import React from "react";
import { Device } from "../../models";
import styles from "./styles.module.scss";
import ReactModal from "react-modal";
import { Api } from "../../Api";
import { Logger } from "../../Logger";
import LocationList from "../locationList/location-list";

class LocationAddDialog extends React.Component<ILocationAddDialogProps, ILocationAddDialogState> {
    constructor(props: ILocationAddDialogProps) {
        super(props);

        this.state = {
            loading: false,
            name: "",
            selectedLocation: null
        };

        this.onSaveLocation = this.onSaveLocation.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
    }

    onSaveLocation() {
        var that = this;
        this.setState({
            loading: true
        });
        if (this.state.selectedLocation && this.state.name) {
            Api.addLocation(this.props.customerId, this.state.selectedLocation, this.state.name).then(res => {
                Logger.LogSuccess("Locatie toegevoegd");
                that.setState({
                    loading: false,
                    name: "",
                    selectedLocation: null
                });
                that.props.modalClosed();
            });
        }
    }

    onChangeName(e: React.FormEvent<HTMLInputElement>) {
        if (e.currentTarget.value && e.currentTarget.value.length <= 500)
            this.setState({ name: e.currentTarget.value });
    }

    locationSelected = (ev: any) => {
        this.setState({ selectedLocation: ev.nodeData.id });
    };

    render() {
        return (
            <ReactModal isOpen={true} className={styles.confirmModal} overlayClassName={styles.overlayConfirmModal} ariaHideApp={false}>
                <label>Naam: </label><input type="text" className="form-control" value={this.state.name} onChange={this.onChangeName} />

                <label style={{ marginTop: 10 }}>Kies een niveau waaronder de nieuwe locatie moet komen:</label>
                <div className={styles.locationContainer}><LocationList customerId={this.props.customerId} locationSelected={this.locationSelected} /></div>

                <div className={styles.buttonContainer}>
                    {this.state.name && this.state.selectedLocation && <button className={[styles.button, 'btn', 'btn-success'].join(' ')} onClick={this.onSaveLocation}>Opslaan</button>}
                    <button className={[styles.button, 'btn', 'btn-danger'].join(' ')} onClick={this.props.modalClosed}>Sluiten</button>
                </div>
            </ReactModal>
        );
    }
}

export default LocationAddDialog;

export interface ILocationAddDialogProps {
    modalClosed: () => void;
    customerId: number;
}

export interface ILocationAddDialogState {
    loading: boolean;
    name: string;
    selectedLocation: number | null;
}