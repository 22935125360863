import React from "react";
import { Device } from "../../models";
import styles from "./styles.module.scss";
import Moment from "react-moment";
import fe from "./fe.svg";
import UsercodeDisplay from "../usercodeDisplay/component";

class DeviceDisplay extends React.Component<IDeviceDisplayProps> {

    render() {
        let imageSrc = "";
        switch (this.props.device.typeCode) {
            case "BRAND":
                imageSrc = fe;
                break;
            default:
                imageSrc = "";
                break;
        }

        return (
            <div>
                <div className={styles.typeImage}><img src={imageSrc} alt="type" /></div>

                <div className={styles.displayRow}>
                    <div className="form-group">
                        <label>Id</label>
                        <small className="form-text text-muted">{this.props.device.c_Id}</small>
                    </div>
                    <div className="form-group">
                        <label>Benor nummer</label>
                        <small className="form-text text-muted">{this.props.device.benorNr}</small>
                    </div>
                    <div className="form-group">
                        <label>Intern nummer</label>
                        <small className="form-text text-muted">{this.props.device.internalNumber}</small>
                    </div>
                </div>

                <div className={styles.displayRow}>
                    <div className="form-group">
                        <label>Type toestel</label>
                        <small className="form-text text-muted">
                            <UsercodeDisplay code={this.props.device.c_TypeCode} groupCode="FETYP" />
                        </small>
                    </div>
                    <div className="form-group">
                        <label>Merk</label>
                        <small className="form-text text-muted">
                            <UsercodeDisplay code={this.props.device.brandCode} groupCode="FEBRA" />
                        </small>
                    </div>
                    <div className="form-group">
                        <label>Fabricagejaar</label>
                        <small className="form-text text-muted">{this.props.device.year}</small>
                    </div>
                </div>

                <div className={styles.displayRow}>
                    <div className="form-group">
                        <label>Tijdstip laatste keuring</label>
                        <small className="form-text text-muted">
                            <Moment format="DD/MM/YYYY">{this.props.device.timestamp}</Moment>
                        </small>
                    </div>
                    <div className="form-group">
                        <label>Volgende keuring</label>
                        <small className="form-text text-muted">
                            <Moment format="DD/MM/YYYY">{this.props.device.nextInspectionDate}</Moment>
                        </small>
                    </div>
                    <div></div>
                </div>

                <div className={styles.displayRow}>
                    <div className="form-group">
                        <label>Locatie</label>
                        <small className="form-text text-muted">{this.props.device.c_Location}</small>
                    </div>
                    <div className="form-group">
                        <label>Gewicht</label>
                        <small className="form-text text-muted">{this.props.device.weight}</small>
                    </div>
                    <div></div>
                </div>

                <div className={styles.displayRow}>
                    <div className="form-group">
                        <label>Status</label>
                        <small className="form-text text-muted">
                            <UsercodeDisplay code={this.props.device.statusCode} groupCode="FESTA" />
                        </small>
                    </div>
                    <div className="form-group">
                        <label>Reden afkeuren</label>
                        <small className="form-text text-muted">
                            <UsercodeDisplay code={this.props.device.causeRejectionCode} groupCode="FECRE" />
                        </small>
                    </div>
                    <div></div>
                </div>

                <div className={styles.displayRow}>
                    <div className="form-group">
                        <label>Opmerkingen</label>
                        <small className="form-text text-muted">{this.props.device.remarks}</small>
                    </div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
}

export default DeviceDisplay;

export interface IDeviceDisplayProps {
    device: Device;
}