import "react-app-polyfill/ie11";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/styles/rsuite-default.css';

import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-lists/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-richtexteditor/styles/material.css';
import '@syncfusion/ej2-inplace-editor/styles/material.css';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';

//fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faPowerOff, faList, faMinusCircle, faPlusCircle, faSave, faQrcode, faUserFriends, faUpload, faDownload, faEdit, faHome, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
library.add(faUser, faPowerOff, faList, faMinusCircle, faPlusCircle, faSave, faQrcode, faUserFriends, faUpload, faDownload, faEdit, faHome, faPencilAlt);
ReactDOM.render(<App />, document.getElementById('root'));