import React, { ChangeEvent } from "react";
import { Api } from "../../Api";
import { Location } from "../../models";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import _ from "lodash";
import Loader from "../loader/component";
import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logger } from "../../Logger";
import styles from "./location-contact.module.scss";

class LocationContact extends React.Component<ILocationContactProps, ILocationContactState> {
    private showOnReport: CheckBoxComponent | null = null;
    private name: InPlaceEditorComponent | null = null;
    private contact: InPlaceEditorComponent | null = null;
    private phone: InPlaceEditorComponent | null = null;
    private remarks: InPlaceEditorComponent | null = null;

    constructor(props: ILocationContactProps) {
        super(props);

        this.state = {
            loading: true,
            location: null
        };

        this.onSaveContact = this.onSaveContact.bind(this);
    }

    componentDidMount() {
        this.loadLocationContacts(this.props.locationId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.locationId !== prevProps.locationId)
            this.loadLocationContacts(this.props.locationId);
    }

    loadLocationContacts(locationId: number) {
        var that = this;
        this.setState({ loading: true });

        if (locationId > 0) {
            Api.getLocation(locationId).then((res: Location) => {
                that.setState({
                    loading: false,
                    location: res
                });
            });
        }
    }

    onChangeAdmin(event: React.ChangeEvent<HTMLInputElement>) {
        console.log("test");
    }

    onSaveContact() {
        var that = this;
        var location = new Location();

        location.id = this.props.locationId;
        if (this.name?.value != null) { location.name = this.name.value.toString() };
        if (this.contact?.value != null) { location.contact = this.contact.value.toString() };
        if (this.phone?.value != null) { location.contactPhone = this.phone.value.toString() };
        if (this.remarks?.value != null) { location.remarks = this.remarks.value.toString() };
        if (this.showOnReport != null) { location.showOnReport = this.showOnReport.checked };

        this.setState({
            loading: true
        });

        Api.saveLocation(location).then(res => {
            Logger.LogSuccess("Opgeslagen");
            that.loadLocationContacts(this.props.locationId);
        });
    }

    render() {
        return (
            <div>
                {this.state.loading && <Loader />}
                {!this.state.loading && this.state.location != null && <div>
                    <h1>Locatie details</h1>
                    <table>
                        <tbody>
                            <tr>
                                <td><label>Opnemen in rapport:</label></td>
                                <td className={styles.showOnReport}>
                                    <CheckBoxComponent name="ShowOnReport"
                                        ref={(e) => { this.showOnReport = e }}
                                        checked={this.state.location.showOnReport} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Naam:</label>
                                </td>
                                <td>
                                    <InPlaceEditorComponent
                                        mode='Inline' type='Text' value={this.state.location.name}
                                        ref={(e) => { this.name = e }}
                                        emptyText=""
                                        model={{ placeholder: 'Naam' }}
                                        popupSettings={{ title: 'Naam' }}>
                                    </InPlaceEditorComponent>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Contactpersoon:</label>
                                </td>
                                <td>
                                    <InPlaceEditorComponent
                                        mode='Inline' type='Text' value={this.state.location.contact}
                                        ref={(e) => { this.contact = e }}
                                        emptyText=""
                                        model={{ placeholder: 'Contactpersoon' }}
                                        popupSettings={{ title: 'Contactpersoon' }}>
                                    </InPlaceEditorComponent>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Contact telefoon:</label>
                                </td>
                                <td>
                                    <InPlaceEditorComponent
                                        mode='Inline' type='Text' value={this.state.location.contactPhone}
                                        ref={(e) => { this.phone = e }}
                                        emptyText=""
                                        model={{ placeholder: 'Contact telefoon' }}
                                        popupSettings={{ title: 'Contact telefoon' }}>
                                    </InPlaceEditorComponent>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Opmerkingen:</label>
                                </td>
                                <td>
                                    <InPlaceEditorComponent
                                        mode='Inline' type='Text' value={this.state.location.remarks}
                                        ref={(e) => { this.remarks = e }}
                                        emptyText=""
                                        model={{ placeholder: 'Opmerkingen' }}
                                        popupSettings={{ title: 'Opmerkingen' }}>
                                    </InPlaceEditorComponent>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button type="button" className="btn btn-md btn-success" onClick={this.onSaveContact}><FontAwesomeIcon icon="save" /> Opslaan</button> 
                </div>}
            </div>
        );
    }
}

export default LocationContact;

export interface ILocationContactProps {
    locationId: number;
}

export interface ILocationContactState {
    loading: boolean;
    location: Location | null;
}