import React from "react";
import styles from "./login.module.scss";
import logo from "./FireSafePlus.svg";
import { AuthenticationService } from "../../authenticationService";
import Loader from "../../components/loader/component";
import { Route, Switch, Redirect } from "react-router-dom";
import Register from "./register";

class CustomerLogin extends React.Component<ICustomerLoginProps, ICustomerLoginState> {
    private _authService: AuthenticationService;

    constructor(props: ICustomerLoginProps) {
        super(props);

        this._authService = props.authenticationService;

        this.state = {
            password: "",
            username: "",
            loading: false
        };

        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    onChangeUsername = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            username: e.currentTarget.value
        });
    }

    onChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            password: e.currentTarget.value
        });
    }

    handleLoginClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({ loading: true });
        this._authService.customerLogin(this.state.username, this.state.password, () => {
            this.setState({ loading: false });
        });
    }

    render() {
        return (
            <div>
                <div className={styles.logoContainer}>
                    <img src={logo} alt="firesafeplus" height="250" />
                </div>

                <div className={styles.loginContainer}>
                    <div>
                        <label htmlFor="Klantcode">Klantcode</label>
                        <input className="form-control" id="Email" name="Email" type="text" value={this.state.username} placeholder="Email adres" onChange={this.onChangeUsername} />
                    </div>
                    <div>
                        <label htmlFor="Klantwachtwoord">Wachtwoord</label>
                        <input className="form-control" id="Password" name="Password" type="password" value={this.state.password} placeholder="Wachtwoord" onChange={this.onChangePassword} />
                    </div>
                    <div>
                        {this.state.loading ? <div className={styles.loaderContainer}><Loader /></div> : <button type="button" className="btn btn-success" onClick={this.handleLoginClick}>Log in</button>}
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerLogin;

export interface ICustomerLoginProps {
    authenticationService: AuthenticationService;
}

export interface ICustomerLoginState {
    username: string;
    password: string;

    loading: boolean;
}