export class Device {
    constructor() {
        this.id = 0;
        this.identifier = "";
        this.typeCode = "";
        this.images = "";
        this.benorNr = "";
        this.internalNumber = "";
        this.c_TypeCode = "";
        this.brandCode = "";
        this.year = 0;
        this.weight = 0;
        this.c_Location = "";
        this.remarks = "";
        this.statusCode = "";
        this.causeRejectionCode = "";
        this.locationId = 0;
        this.timestamp = new Date();
        this.nextInspectionDate = new Date();
        this.typeFull = "";
        this.customerId = 0;
        this.customerName = "";
        this.parts = [];
    }

    id: number;
    identifier: string;
    typeCode: string;
    locationId: number;
    images: string;

    c_Id; number;
    c_TypeCode: string;
    c_Location: string;
    benorNr: string;
    brandCode: string;
    internalNumber: string;
    year: number;
    weight: number;
    remarks: string;
    statusCode: string;
    nextInspectionDate: Date;
    causeRejectionCode: string;
    timestamp: Date;
    typeFull: string;
    customerId: number;
    customerName: string;
    parts: string[];
}

export class FireExtinguisher {
    constructor() {
        this.ID = 0;
        this.benorNr = "";
        this.internalNumber = "";
        this.c_TypeCode = "";
        this.brandCode = "";
        this.year = 0;
        this.weight = 0;
        this.c_Location = "";
        this.remarks = "";
        this.statusCode = "";
        this.causeRejectionCode = "";
        this.locationId = 0;
        this.customerId = 0;
        this.timestamp = new Date();
    }

    ID: number;
    benorNr: string;
    internalNumber: string;
    c_TypeCode: string;
    brandCode: string;
    year: number;
    weight: number;
    c_Location: string;
    remarks: string;
    statusCode: string;
    causeRejectionCode: string;
    locationId: number;
    customerId: number;
    timestamp: Date;
}