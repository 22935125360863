export interface IUserCodeGroup {
    groupCode: string;
    description: string;
}

export class UserCode {
    constructor(groupCode: string) {
        this.id = 0;
        this.code = "";
        this.groupCode = groupCode;
        this.description = "";
        this.active = true;
        this.sortOrder = 0;

        this.markedForDeletion = false;
    }

    id: number;
    code: string;
    groupCode: string;
    description: string;
    active: boolean;
    sortOrder: number;

    //custom props
    markedForDeletion: boolean;
}