import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import React from "react";
import { Device } from "../../models";
import DeviceDetail from "./deviceDetail";

class DeviceList extends React.Component<IDeviceListProps> {

    render() {
        return (
            <div>
                {this.props.devices && this.props.devices.length > 0 && <AccordionComponent>
                    {this.props.devices.map(function (item: Device) {
                        return <DeviceDetail device={item} />
                    })}
                </AccordionComponent>}
                {this.props.devices && this.props.devices.length == 0 && <div>Geen toestellen</div>}
            </div>
        );
    }
}

export default DeviceList;

export interface IDeviceListProps {
    devices: Device[];
}