import {
    ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Grid,
    PageSettingsModel, Sort, FilterSettingsModel, SortSettingsModel, SelectionSettingsModel
} from '@syncfusion/ej2-react-grids';

import React from "react";
import { Api } from "../../Api";
import { Device } from "../../models";

class DeviceList extends React.Component<IDeviceListProps, IDeviceListState> {
    private grid: Grid | null;

    constructor(props: IDeviceListProps) {
        super(props);

        this.grid = null;

        this.state = {
            loading: true,
            devices: [],
            selectedDevice: null,
            pageSettings: { pageSize: 10 },
            sortSettings: { allowUnsort: false },
            filterSettings: {
                mode: "Immediate",
                immediateModeDelay: 200
            },
            selectionSettings: {
                mode: "Row"
            }
        };
    }

    componentDidMount() {
        this.loadDevices(this.props.locationId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.locationId !== prevProps.locationId)
            this.loadDevices(this.props.locationId);
    }

    loadDevices(locationId: number) {
        Api.getDeviceData(this.props.customerId, locationId).then((res: Device[]) => {
            this.setState({
                loading: false,
                devices: res
            });
        });
    }

    actionBegin(e: any) {
        if (e.requestType === 'filtering') {
            if (e.columns)
                e.columns.forEach((col: any) => {
                    col.operator = 'contains';
                });
        }
    }

    render() {
        return (
            <div>
                {!this.state.loading && this.state.devices && this.state.devices.length > 0 &&
                    <GridComponent ref={g => this.grid = g}
                        dataSource={this.state.devices} actionBegin={this.actionBegin}
                        selectionSettings={this.state.selectionSettings} rowSelected={this.props.deviceSelected}
                        allowPaging={true} pageSettings={this.state.pageSettings}
                        allowSorting={true} sortSettings={this.state.sortSettings}
                        allowFiltering={true} filterSettings={this.state.filterSettings}>
                        <ColumnsDirective>
                            <ColumnDirective field='id' width='100' />
                            <ColumnDirective field='benorNr' width='100' />
                            <ColumnDirective field='typeCode' width='100' />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter, Group]} />
                    </GridComponent>}
            </div>
        );
    }
}

export default DeviceList;

export interface IDeviceListProps {
    locationId: number;
    customerId: number;
    deviceSelected: (ev: any) => void;
}

export interface IDeviceListState {
    loading: boolean;
    devices: Device[];
    selectedDevice: Device | null;

    pageSettings: PageSettingsModel;
    sortSettings: SortSettingsModel;
    filterSettings: FilterSettingsModel;
    selectionSettings: SelectionSettingsModel;
}