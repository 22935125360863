import React from "react";
import styles from "./users.module.scss";
import { AuthenticationService } from "../../authenticationService";
import { Api } from "../../Api";
import { User } from "../../models";
import Loader from "../../components/loader/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logger } from "../../Logger";

class Users extends React.Component<IUsersProps, IUsersState> {
    private _authService: AuthenticationService;

    constructor(props: IUsersProps) {
        super(props);

        this._authService = props.authenticationService;

        this.state = {
            loading: true,
            showAdd: false,
            users: [],
            email: "",
            isAdmin: false,
            isConsultant: false
        };

        this.onAddUser = this.onAddUser.bind(this);
        this.onAddSubmitUser = this.onAddSubmitUser.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeAdmin = this.onChangeAdmin.bind(this);
        this.onChangeConsultant = this.onChangeConsultant.bind(this);
    }

    componentDidMount() {
        //load data
        this.loadUsers();
    }

    loadUsers() {
        Api.getAllUsers().then((res: User[]) => {
            this.setState({
                loading: false,
                users: res
            });
        });
    }

    onAddUser() {
        this.setState({ showAdd: true });
    }

    onAddSubmitUser() {
        if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)) {
            this.setState({ loading: true });

            var user = new User();
            user.email = this.state.email;
            user.isAdmin = this.state.isAdmin;
            user.isConsultant = this.state.isConsultant;

            Api.addUser(user);
        } else {
            Logger.LogErrorMessage("Ongeldig email adres");
        }
    }

    onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ email: event.target.value });
    }


    onChangeAdmin(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ isAdmin: event.target.checked });
    }

    onChangeConsultant(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ isConsultant: event.target.checked });
    }

    render() {
        return (
            <div className={styles.container}>
                <h1>Overzicht gebruikers</h1>
                {this.state.loading && <Loader />}
                {!this.state.loading &&
                    <table className={styles.customTable + " table"}>
                        <thead>
                            <tr>
                                <th scope="col">Email</th>
                                <th scope="col">Beheerder</th>
                                <th scope="col">Firesafeplus consultant</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.map(function (item: User) {
                                return <tr key={item.id}>
                                    <td>{item.email}</td>
                                    <td>{item.isAdmin ? "Ja" : "Neen"}</td>
                                    <td>{item.isConsultant ? "Ja" : "Neen"}</td>
                                    <td>{item.status}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                }

                {!this.state.loading && !this.state.showAdd && <div>
                    <button type="button" className="btn btn-primary" onClick={this.onAddUser}><FontAwesomeIcon icon="plus-circle" /> Toevoegen</button>
                </div>}

                {!this.state.loading && this.state.showAdd && <div>
                    <h1>Nieuwe gebruiker</h1>

                    <div className={styles.newUser}>
                        <input type="text" className="form-control" placeholder="Email" value={this.state.email} onChange={this.onChangeEmail} />
                        <label><input type="checkbox" name="isAdmin" checked={this.state.isAdmin} onChange={this.onChangeAdmin} /> Beheerder</label><br />
                        <label><input type="checkbox" name="isConsultant" checked={this.state.isConsultant} onChange={this.onChangeConsultant} /> Firesafeplus consultant</label><br />
                        <button type="button" className="btn btn-success" onClick={this.onAddSubmitUser}><FontAwesomeIcon icon="save" /> Opslaan</button>
                    </div>
                </div>}
            </div>
        );
    }
}

export default Users;

export interface IUsersProps {
    authenticationService: AuthenticationService;
}

export interface IUsersState {
    loading: boolean;
    showAdd: boolean;
    users: User[];

    email: string;
    isAdmin: boolean;
    isConsultant: boolean;
}