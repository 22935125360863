import React from "react";
import styles from "./home.module.scss";
import { AuthenticationService } from "../../authenticationService";
import { Api } from "../../Api";
import { ICustomerNextInspection, User } from "../../models";
import Loader from "../../components/loader/component";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";

class Home extends React.Component<IHomeProps, IHomeState> {
    private _authService: AuthenticationService;

    constructor(props: IHomeProps) {
        super(props);

        this._authService = props.authenticationService;

        this.state = {
            loading: true,
            nextInspections: []
        };
    }

    componentDidMount() {
        //load data
        this.loadDashboard();
    }

    loadDashboard() {
        Api.getOverview().then((res: ICustomerNextInspection[]) => {
            this.setState({
                loading: false,
                nextInspections: res
            });
        });
    }


    render() {
        return (
            <div className={styles.container}>
                <h1>Volgende keuringen</h1>
                {this.state.loading && <Loader />}
                {!this.state.loading && this.state.nextInspections &&
                    <ul>
                    {this.state.nextInspections.map(function (item: ICustomerNextInspection) {
                        return <li><Moment format="DD/MM/YYYY">{item.nextInspection}</Moment> /
                                   <NavLink to={"/customers/" + item.customerId}> {item.customer} </NavLink> /
                                   #: {item.totalDevices}
                               </li>
                    })}
                    </ul>
                }
            </div>
        );
    }
}

export default Home;

export interface IHomeProps {
    authenticationService: AuthenticationService;
}

export interface IHomeState {
    loading: boolean;
    nextInspections: ICustomerNextInspection[];
}