import React from "react";
import { Api } from "../../Api";
import styles from "./login.module.scss";
import Loader from "../../components/loader/component";
import { Logger } from "../../Logger";
import { withRouter, RouteComponentProps } from "react-router-dom";

class Register extends React.Component<IRegisterProps, IRegisterState> {

    constructor(props: IRegisterProps) {
        super(props);

        this.state = {
            loading: true,
            email: "",
            password: "",
            passwordRepeat: ""
        };

        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangePasswordRepeat = this.onChangePasswordRepeat.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
    }

    componentDidMount() {
        this.getEmailForId();
    }

    getEmailForId() {
        Api.getEmailForId(this.props.id).then((response: string) => {
            this.setState({ loading: false, email: response });
        });
    }

    onChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            password: e.currentTarget.value
        });
    }

    onChangePasswordRepeat = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            passwordRepeat: e.currentTarget.value
        });
    }

    handleActivate(event: React.MouseEvent<HTMLButtonElement>) {
        if (this.state.password === this.state.passwordRepeat) {
            if (this.state.password.length < 8) {
                Logger.LogErrorMessage("Wachtwoord moet minstens 8 tekens zijn");
            } else {
                event.preventDefault();
                this.setState({ loading: true });
                Api.setPassword(this.props.id, this.state.password).then(() => {
                    this.props.history.push("login");
                });
            }
        } else {
            Logger.LogErrorMessage("Passwoorden zijn niet gelijk");
        }
    }

    render() {
        return (
            this.state.loading ? <Loader /> : <div className={styles.loginContainer}>
                <div>
                    <label htmlFor="Email">Email</label>
                    <input className="form-control" id="Email" name="Email" type="text" value={this.state.email} disabled={true} />
                </div>
                <div>
                    <label htmlFor="Password">Kies wachtwoord</label>
                    <input className="form-control" id="Password" name="Password" type="password" value={this.state.password} onChange={this.onChangePassword} />
                </div>
                <div>
                    <label htmlFor="Password">Herhaal wachtwoord</label>
                    <input className="form-control" id="Password" name="Password" type="password" value={this.state.passwordRepeat} onChange={this.onChangePasswordRepeat} />
                </div>
                <div>
                    {this.state.loading ? <div className={styles.loaderContainer}><Loader /></div> : <button type="button" className="btn btn-success" onClick={this.handleActivate}>Activeer account!</button>}
                </div>
            </div>
        );
    }
}

export default withRouter(Register);

export interface IRegisterProps extends RouteComponentProps {
    id: string;
}

export interface IRegisterState {
    loading: boolean;
    email: string;

    password: string;
    passwordRepeat: string;
}