import React from "react";
import { Device } from "../../models";
import styles from "./styles.module.scss";
import ReactModal from "react-modal";
import DeviceDisplay from "../deviceDisplay/device-display";

class DeviceDialog extends React.Component<IDeviceDialogProps, IDeviceDialogState> {
    constructor(props: IDeviceDialogProps) {
        super(props);

        this.state = {
            loading: true
        };
    }

    render() {
        return (
            <ReactModal isOpen={true} className={styles.confirmModal} overlayClassName={styles.overlayConfirmModal} ariaHideApp={false}>
                <DeviceDisplay device={this.props.device} />

                <div className={styles.buttonContainer}>
                    <button className={[styles.button, 'btn', 'btn-danger'].join(' ')} onClick={this.props.modalClosed}>Sluiten</button>
                </div>
            </ReactModal>
        );
    }
}

export default DeviceDialog;

export interface IDeviceDialogProps {
    device: Device;
    modalClosed: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface IDeviceDialogState {
    loading: boolean;
}