import { AccessRights, IUserCodeGroup, UserCode, User, ICustomer, Location, Device, ICustomerJob, FireExtinguisher, ICustomerNextInspection } from "./models";

import axios from "axios";
import { Logger } from "./Logger";
axios.defaults.withCredentials = true;

//Update session last success date on each response to create sliding expiration
axios.interceptors.response.use(function (response) {
    localStorage.setItem("lastLogin", new Date().toUTCString());
    return response;
}, function (error) {
    if (500 === error.response.status)
        Logger.LogErrorMessage("fout opgetreden");
    return Promise.reject(error);
});

//Add token on each request if session not expired
axios.interceptors.request.use(function (config) {
    var lastLogin = localStorage.getItem("lastLogin");
    if (lastLogin) {
        var lastLoginDate = new Date(lastLogin);
        if (new Date().getTime() < (lastLoginDate.getTime() + 4 * 3600 * 1000)) { //add 2 hours expiration
            config.headers = { Authorization: `Basic ${localStorage.getItem("token")}` };
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export class Api {
    public static async downloadQR() {
        axios({
            url: '/api/qrcode/10',
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'codes.pdf');
            document.body.appendChild(link);
            link.click();
        });
    }

    public static async downloadList(customerId: number, locationId: number) {
        axios({
            url: `/api/PDF/customerlist/${customerId}/${locationId}`,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'overzicht.pdf');
            document.body.appendChild(link);
            link.click();
        });
    }

    public static async downloadListNew(customerId: number, locationId: number) {
        return new Promise<void>((resolve, reject) => {
            axios({
                url: `/api/PDF/customerlist/${customerId}/${locationId}/new`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'overzicht.pdf');
                document.body.appendChild(link);
                link.click();
                resolve();
            });
        });
    }

    public static async getOverview() {
        const response = await axios.get<ICustomerNextInspection[]>('/api/home/overview');
        return response.data;
    }

    public static async getCustomers() {
        const response = await axios.get<ICustomer[]>('/api/customer');
        return response.data;
    }

    public static async addCustomer(name: string) {
        return await axios.post('/api/customer', { Name: name });
    }

    public static async updateName(id: number, name: string) {
        return await axios.put('/api/customer', { Id: id, Name: name });
    }

    public static async updateCompanyCodes(id: number, code: string, secret: string) {
        return await axios.post(`/api/customer/${id}`, { CompanyCode: code, CompanySecret: secret });
    }

    public static async getUserCodeGroups() {
        const response = await axios.get<IUserCodeGroup[]>('/api/usercode/groupcodes');
        return response.data;
    }

    public static async getUserCode(groupCode: string) {
        const response = await axios.get<UserCode[]>(`/api/usercode/${groupCode}/codes`);
        return response.data;
    }

    public static async getAllUserCodes() {
        const response = await axios.get<UserCode[]>(`/api/usercode/list-all`);
        return response.data;
    }

    public static async submitUserCodes(groupCode: string, codes: UserCode[]) {
        const response = await axios.post<boolean>(`/api/usercode/${groupCode}/codes`, codes);
        return response.data;
    }

    public static async reLogin() {
        return axios.get<AccessRights>('/api/users/authenticate');
    }

    public static async getAuthorizations(username: string, password: string) {
        return axios.post<AccessRights>('/api/users/authenticate', { username: username, password: password });
    }

    public static async getAllUsers() {
        const response = await axios.get<User[]>('/api/user/list');
        return response.data;
    }

    public static async getEmailForId(id: string) {
        const response = await axios.get<string>(`/api/user/${id}`);
        return response.data;
    }

    public static async addUser(user: User) {
        return axios.post<AccessRights>('/api/user/', user);
    }

    public static async setPassword(hash: string, pw: string) {
        return axios.post(`/api/user/${hash}`, { password: pw });
    }

    public static async getLocationData(customerId: number) {
        const response = await axios.get<Location>(`/api/location/customer/${customerId}`);
        return response.data;
    }

    public static async getLocation(locationId: number) {
        const response = await axios.get<Location>(`/api/location/${locationId}`);
        return response.data;
    }

    public static async addLocation(customerId: number, parentId: number, name: string) {
        return axios.post(`/api/location/${customerId}/${parentId}/${name}`);
    }

    public static async saveLocation(location: Location) {
        return axios.put(`/api/location`, location);
    }

    public static async getDeviceData(customerId: number, locationId: number) {
        const response = await axios.get<Device[]>(`/api/device/${customerId}/location/${locationId}`);
        return response.data;
    }

    public static async getAllDevices() {
        const response = await axios.get<Device[]>(`/api/device/list`);
        return response.data;
    }

    public static async getDevicePublicData(code: string) {
        const response = await axios.get<Device>(`/api/device/from-code/${code}`);
        return response.data;
    }

    public static async updateOrAddFireExtinguisher(device: FireExtinguisher) {
        const response = device.ID === 0 ? await axios.post(`/api/device/fe`, device) : await axios.put(`/api/device/fe`, device);
        return response.data;
    }

    public static async getJobs() {
        const response = await axios.get<ICustomerJob[]>(`/api/customerjob`);
        return response.data;
    }

    public static async getJob(jobId: number) {
        const response = await axios.get<ICustomerJob>(`/api/customerjob/${jobId}`);
        return response.data;
    }

    public static async FinishJob(jobId: number) {
        await axios.post(`/api/customerjob/${jobId}/finish`);
    }
}