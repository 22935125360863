import React from "react";
import styles from "./edit.module.scss";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Filter, Sort, PageSettingsModel, SortSettingsModel, FilterSettingsModel, SelectionSettingsModel, Toolbar } from '@syncfusion/ej2-react-grids';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import { Device } from "../../models";
import { Api } from "../../Api";
import DeviceEdit from "../../components/deviceEdit/device-edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EditPage extends React.Component<IEditPageProps, IEditPageState> {
    public data = new DataManager({
        url: '/api/device/list',
        adaptor: new WebApiAdaptor(),
        headers: [{
            Authorization: `Basic ${localStorage.getItem("token")}`
        }]
    });
    public gridInstance: GridComponent | null;
    public toolbarOptions: any = ['Search'];

    constructor(props: IEditPageProps) {
        super(props);

        this.state = {
            loading: true,
            devices: [],
            pageSettings: { pageSize: 10, pageCount: 5 },
            sortSettings: { allowUnsort: false },
            filterSettings: {
                mode: "Immediate",
                immediateModeDelay: 200
            },
            selectionSettings: {
                mode: "Row"
            },
            selectedDevice: null
        };

        this.gridInstance = null;
        this.onAddDevice = this.onAddDevice.bind(this);
    }

    componentDidMount() {
        this.loadDevices();
    }

    loadDevices() {
        Api.getAllDevices().then((res: Device[]) => {
            this.setState({ loading: false, devices: res });
        });
    }

    rowSelected = () => {
        var that = this;
        that.setState({ selectedDevice: null }, () => {
            if (that.gridInstance) {
                that.setState({ selectedDevice: that.gridInstance.getSelectedRecords()[0] as Device });
            }
        });
    };


    onAddDevice() {
        var newDevice = new Device();
        this.setState({ selectedDevice: newDevice });
    }

    actionBegin(e: any) {
        if (e.requestType === 'filtering') {
            if (e.columns)
                e.columns.forEach((col: any) => {
                    col.operator = 'contains';
                });
        }
    }

    render() {
        return (
            <div className={styles.container}>
                <button type="button" className="btn btn-md btn-primary" onClick={this.onAddDevice}><FontAwesomeIcon icon="plus-circle" /> Toevoegen</button> 

                {!this.state.loading && this.state.devices && this.state.devices.length > 0 && <div className='control-pane'>
                    <div className='control-section'>
                        <GridComponent id="Grid" dataSource={this.state.devices} ref={grid => this.gridInstance = grid}
                            //toolbar={this.toolbarOptions} 
                            actionBegin={this.actionBegin}
                            selectionSettings={this.state.selectionSettings} rowSelected={this.rowSelected}
                            allowPaging={true} pageSettings={this.state.pageSettings}
                            allowSorting={true} sortSettings={this.state.sortSettings}
                            allowFiltering={true} filterSettings={this.state.filterSettings}>
                            <ColumnsDirective>
                                <ColumnDirective field='id' width='20' />
                                <ColumnDirective field='customerName' width='100' headerText="Klant" />
                                <ColumnDirective field='benorNr' width='100' headerText="Benor-nr" />
                                <ColumnDirective field='c_Location' width='100' headerText="Locatie" />
                            </ColumnsDirective>
                            <Inject services={[Filter, Page, Sort, Toolbar]} />
                        </GridComponent>
                    </div>
                    <div id='waitingpopup' className='waitingpopup'>
                        <span id='gif' className='image'></span>
                    </div>
                </div>}

                {this.state.selectedDevice !== null && <DeviceEdit device={this.state.selectedDevice} />}
            </div>
        );
    }
}

export default EditPage;

export interface IEditPageProps {

}

export interface IEditPageState {
    loading: boolean;
    devices: Device[];
    selectedDevice: Device | null;

    pageSettings: PageSettingsModel;
    sortSettings: SortSettingsModel;
    filterSettings: FilterSettingsModel;
    selectionSettings: SelectionSettingsModel;
}