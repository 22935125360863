import React from "react";
import styles from "./usercodes.module.scss";
import { AuthenticationService } from "../../authenticationService";
import { IUserCodeGroup } from "../../models/index";
import { Api } from "../../Api";
import Loader from "../../components/loader/component";
import { NavLink } from "react-router-dom";
import { Route } from "react-router-dom";
import UsercodeDetail from "./usercodeDetail";

class Usercodes extends React.Component<IUsercodeProps, IUsercodeState> {
    private _authService: AuthenticationService;

    constructor(props: IUsercodeProps) {
        super(props);

        this._authService = props.authenticationService;

        this.state = {
            loading: true,
            userCodeGroups: []
        };
    }

    componentDidMount() {
        this.loadUserCodeGroups();
    }

    loadUserCodeGroups() {
        Api.getUserCodeGroups().then((res: IUserCodeGroup[]) => {
            this.setState({ loading: false, userCodeGroups: res });
        });
    }

    render() {
        return (
            <div className={styles.container}>
                <h1>Kies een lijst</h1>
                {this.state.loading && <Loader />}
                {!this.state.loading && this.state.userCodeGroups.map(function (item: IUserCodeGroup) {
                    return <div key={item.groupCode}><NavLink to={"/usercodes/" + item.groupCode}>{item.groupCode}: {item.description}</NavLink></div>
                })}

                <Route path="/usercodes/:code" render={({ match }) => {
                    return <div>
                        <div className={styles.headerUsercode}>
                            CODE: {match.params.code} <br />
                            Omschrijving: {this.state.userCodeGroups.find(c => c.groupCode === match.params.code)?.description}
                        </div>
                        <UsercodeDetail code={match.params.code} />
                    </div>
                }} />
            </div>
        );
    }
}

export default Usercodes;

export interface IUsercodeProps {
    authenticationService: AuthenticationService;
}

export interface IUsercodeState {
    loading: boolean;
    userCodeGroups: IUserCodeGroup[];
}

export interface IRouteParams {
    id: string;
}