import React from "react";
import Moment from "react-moment";
import { IInspection } from "../../models";
import DeviceList from "./deviceList";

class InspectionList extends React.Component<IInspectionListProps> {

    render() {
        return (
            <div>
                <h3>Inspecties: </h3>
                {this.props.inspections && this.props.inspections.map(function (item: IInspection) {
                    return <div key={item.id}>
                        <span><b>Omschrijving: </b>{item.description}</span><br />
                        <span><b>Start inspectie: </b><Moment format="DD/MM/YYYY HH:mm">{item.start}</Moment></span><br />
                        <span><b>Sync inspectie: </b><Moment format="DD/MM/YYYY HH:mm">{item.submit}</Moment></span><br />
                        <br />

                        <h4>Toestellen inspectie</h4>
                        <DeviceList devices={item.fireExtinguishers} />
                    </div>;
                })}
                {this.props.inspections && this.props.inspections.length == 0 && <div>Geen inspecties gevonden</div>}
            </div>
        );
    }
}

export default InspectionList;

export interface IInspectionListProps {
    inspections: IInspection[];
}