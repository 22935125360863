import React from "react";
import styles from "./login.module.scss";
import logo from "./FireSafePlus.svg";
import { AuthenticationService } from "../../authenticationService";
import Loader from "../../components/loader/component";
import { Route, Switch, Redirect, Link } from "react-router-dom";

class Login extends React.Component<ILoginProps, ILoginState> {
    private _authService: AuthenticationService;

    constructor(props: ILoginProps) {
        super(props);

        this._authService = props.authenticationService;

        this.state = {
            password: "",
            username: "",
            loading: false
        };

        this.handleLoginClick = this.handleLoginClick.bind(this);
    }

    onChangeUsername = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            username: e.currentTarget.value
        });
    }

    onChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            password: e.currentTarget.value
        });
    }

    handleLoginClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({ loading: true });
        this._authService.login(this.state.username, this.state.password, () => {
            this.setState({ loading: false });
        });
    }


    render() {
        return (
            <div>
                <div className={styles.logoContainer}>
                    <img src={logo} alt="firesafeplus" height="250" />
                </div>

                <Switch>
                    <Route path="/login" render={() => {
                        return <div className={styles.loginContainer}>
                            <div>
                                <label htmlFor="Email">Email</label>
                                <input className="form-control" id="Email" name="Email" type="text" value={this.state.username} placeholder="Email adres" onChange={this.onChangeUsername} />
                            </div>
                            <div>
                                <label htmlFor="Password">Password</label>
                                <input className="form-control" id="Password" name="Password" type="password" value={this.state.password} placeholder="Wachtwoord" onChange={this.onChangePassword} />
                            </div>
                            <div>
                                {this.state.loading ? <div className={styles.loaderContainer}><Loader /></div> : <button type="button" className="btn btn-success" onClick={this.handleLoginClick}>Log in</button>}
                            </div>

                            <div className={styles.customerLoginButton}><Link to="/customerlogin">ga naar klantenlogin</Link></div>
                        </div>
                    }} />

                    <Redirect to="/login" />
                </Switch>
            </div>
        );
    }
}

export default Login;

export interface ILoginProps {
    authenticationService: AuthenticationService;
}

export interface ILoginState {
    username: string;
    password: string;

    loading: boolean;
}