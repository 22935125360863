import React from "react";
import { UserCode } from "../../models/index";
import { Api } from "../../Api";
import Loader from "../../components/loader/component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./usercodeDetail.module.scss";
import _ from "lodash";
import { Logger } from "../../Logger";

class UsercodeDetail extends React.Component<IUsercodeDetailProps, IUsercodeDetailState> {

    constructor(props: IUsercodeDetailProps) {
        super(props);

        this.state = {
            loading: true,
            userCodes: []
        };

        this.handleAddRow = this.handleAddRow.bind(this);
        this.handleRemoveRow = this.handleRemoveRow.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.loadUserCodes(this.props.code);
    }

    componentWillReceiveProps(nextProps: IUsercodeDetailProps) {
        if (this.props.code !== nextProps.code)
            this.loadUserCodes(nextProps.code);
    }

    loadUserCodes(code: string) {
        Api.getUserCode(code).then((res: UserCode[]) => {
            var codes = _.filter(res, { active: true });
            codes.forEach((c) => c.markedForDeletion = false);
            this.setState({ loading: false, userCodes: codes });
        });
    }

    handleAddRow(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        var codes = this.state.userCodes;
        codes.push(new UserCode(this.props.code));
        this.setState({ userCodes: codes });
    }

    handleRemoveRow(event: React.MouseEvent<HTMLTableDataCellElement>, index: number) {
        event.preventDefault();
        var codes = this.state.userCodes;
        if (codes[index].id === 0)
            codes.splice(index, 1);
        else
            codes[index].markedForDeletion = !codes[index].markedForDeletion;
        this.setState({ userCodes: codes });
    }

    onChangeCode(event: React.ChangeEvent<HTMLInputElement>, index: number) {
        event.preventDefault();

        if (event.target.value.length > 5)
            return false;

        if (event.target.value.length > 0) {
            var existing = _.find(this.state.userCodes, { code: event.target.value.toUpperCase() });
            if (existing)
                return false;
        }

        var codes = this.state.userCodes;
        codes[index].code = event.target.value.toUpperCase();
        this.setState({ userCodes: codes });
    }

    onChangeDescription(event: React.ChangeEvent<HTMLInputElement>, index: number) {
        event.preventDefault();

        if (event.target.value.length > 200)
            return false;

        var codes = this.state.userCodes;
        codes[index].description = event.target.value;
        this.setState({ userCodes: codes });
    }

    onSubmit() {
        this.setState({ loading: true });
        var codes = _.filter(this.state.userCodes, { markedForDeletion: false });
        Api.submitUserCodes(this.props.code, codes).then(resp => {
            if (resp) {
                Logger.LogSuccess("Opgeslagen");
                this.loadUserCodes(this.props.code);
            } else {
                Logger.LogErrorMessage("Fout tijdens opslaan");
                this.setState({ loading: false });
            }
        });
    }

    render() {
        return (
            <div>
                {this.state.loading && <Loader />}
                {!this.state.loading &&
                    <table className={styles.usercodeTable}>
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Description</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.userCodes.map((item: UserCode, index: number) => {
                                return <tr key={index} className={item.markedForDeletion ? styles.deletionRow : ""}>
                                    {item.id !== 0 && <td className={styles.codeEdit}>{item.code}</td>}
                                    {item.id === 0 && <td className={styles.codeEdit}>
                                        <input type="text" className="form-control" value={item.code} onChange={(e) => this.onChangeCode(e, index)} />
                                    </td>}

                                    <td>
                                        <input type="text" className="form-control" value={item.description} disabled={item.markedForDeletion} onChange={(e) => this.onChangeDescription(e, index)} />
                                    </td>
                                    <td onClick={(e) => this.handleRemoveRow(e, index)}>
                                        <FontAwesomeIcon icon="minus-circle" className={styles.removeButton} />
                                    </td>
                                </tr>;
                            })}
                            <tr>
                                <td colSpan={2}>
                                    <button type="button" className="btn btn-sm btn-primary" onClick={this.handleAddRow}><FontAwesomeIcon icon="plus-circle" /> Voeg code toe</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                <button type="button" className="btn btn-success" onClick={this.onSubmit}><FontAwesomeIcon icon="save" /> Opslaan</button>
            </div>
        );
    }
}

export default UsercodeDetail;

export interface IUsercodeDetailProps {
    code: string;
}

export interface IUsercodeDetailState {
    loading: boolean;
    userCodes: UserCode[];
}