import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect, NavLink } from "react-router-dom";
import Header from "./components/header/header";
import { AuthenticationService } from './authenticationService';
import { Subscription } from 'rxjs';
import Loader from './components/loader/component';
import { ToastContainer } from "react-toastify";
import Login from './pages/login/login';
import CustomerLogin from './pages/login/customerlogin';
import Usercodes from './pages/usercodes/usercodes';
import Users from './pages/users/users';
import QrCodes from './pages/qrcodes/qrcodes';
import Customers from './pages/customers/customers';
import CustomerJobs from './pages/customerjobs/customerjobs';
import PublicDetail from './components/publicDetail/public-detail';
import EditPage from './pages/edit/edit';
import Home from './pages/home/home';
import Register from './pages/login/register';

class App extends React.Component<IAppProperties, IAppState> {
    private readonly authService: AuthenticationService;
    private readonly subscription: Subscription;

    constructor(props: IAppProperties) {
        super(props);

        this.state = {
            loading: true,
            isAuthenticated: false,
            isAdmin: false,
            isCustomer: false,
            customerCode: ""
        };

        this.authService = new AuthenticationService();

        var that = this;
        this.subscription = this.authService
            .asObservable()
            .subscribe(res => {
                if (res === "UserLoggedIn" || res === "UserLoggedOut") {
                    that.setState({
                        loading: false,
                        isAdmin: that.authService.isAdministrator(),
                        isAuthenticated: that.authService.isAuthenticated(),
                        isCustomer: (that.authService.getAccessRights().customerCode != null && that.authService.getAccessRights().customerCode != ""),
                        customerCode: that.authService.getAccessRights().customerCode
                    });
                }
                if (res === "UserUnknown")
                    that.setState({ loading: false });
            });

        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidMount() {
        this.authService.init();
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    handleLogout() {
        this.authService.logOut();
        this.setState({ isAuthenticated: false, isAdmin: false });
    };

    render() {
        return (
            <Router>
                <ToastContainer />
                <Route path="/d/:code" render={({ match }) => {
                    return <PublicDetail code={match.params.code} />;
                }} />
                {this.state.loading && <Loader />}
                {!this.state.loading && !this.state.isAuthenticated &&
                    <Switch>
                        <Route path="/d/:code" render={({ match }) => {
                            return <span></span>;
                        }} />
                        <Route path="/login" render={() => {
                            return <Login authenticationService={this.authService} />;
                        }} />
                        <Route path="/customerlogin" render={() => {
                            return <CustomerLogin authenticationService={this.authService} />;
                        }} />
                        <Route exact path="/register/:id" render={({ match }) => {
                            return <Register id={match.params.id} />
                        }} />
                        <Redirect from="/" to="/login" />
                    </Switch>
                }
                {!this.state.loading && this.authService && this.state.isAuthenticated &&
                    <div>
                        {this.state.isCustomer && <Switch>
                            <Route path="/jobs">
                                <Header isAdmin={this.state.isAdmin} isCustomer={this.state.isCustomer} logOut={this.handleLogout}></Header>
                                <CustomerJobs isCustomer={true} />
                            </Route>
                            <Redirect from="/" to="/jobs" />
                        </Switch>}
                        {!this.state.isCustomer &&
                            <Switch>
                                <Route path="/d/:code">
                                    <NavLink to="/users">Ga naar overzicht</NavLink>
                                </Route>
                                <Route path="/home">
                                    <       Header isAdmin={this.state.isAdmin} isCustomer={false} logOut={this.handleLogout}></Header>
                                    <Home authenticationService={this.authService} />
                                </Route>
                                <Route path="/users">
                                    <Header isAdmin={this.state.isAdmin} isCustomer={false} logOut={this.handleLogout}></Header>
                                    <Users authenticationService={this.authService} />
                                </Route>
                                <Route path="/usercodes">
                                    <Header isAdmin={this.state.isAdmin} isCustomer={false} logOut={this.handleLogout}></Header>
                                    <Usercodes authenticationService={this.authService} />
                                </Route>
                                <Route path="/qr-codes">
                                    <Header isAdmin={this.state.isAdmin} isCustomer={false} logOut={this.handleLogout}></Header>
                                    <QrCodes authenticationService={this.authService} />
                                </Route>
                                <Route path="/customers/:id?">
                                    <Header isAdmin={this.state.isAdmin} isCustomer={false} logOut={this.handleLogout}></Header>
                                    <Customers />
                                </Route>
                                <Route path="/edit">
                                    <Header isAdmin={this.state.isAdmin} isCustomer={false} logOut={this.handleLogout}></Header>
                                    <EditPage />
                                </Route>
                                <Route path="/jobs">
                                    <Header isAdmin={this.state.isAdmin} isCustomer={false} logOut={this.handleLogout}></Header>
                                    <CustomerJobs isCustomer={false} />
                                </Route>
                                <Redirect from="/" to="/home" />
                            </Switch>
                        }
                    </div>
                }
            </Router>
        );
    }
}

export default App;

export interface IAppState {
    loading: boolean;
    isAdmin: boolean;
    isAuthenticated: boolean;
    isCustomer: boolean;
    customerCode: string;
}

export interface IAppProperties {

}