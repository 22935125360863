import React from "react";
import { Api } from "../../Api";
import { Location } from "../../models";
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import _ from "lodash";

class LocationList extends React.Component<ILocationListProps, ILocationListState> {

    constructor(props: ILocationListProps) {
        super(props);

        this.state = {
            loading: true,
            fields: {}
        };
    }

    componentDidMount() {
        this.loadLocations(this.props.customerId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerId !== prevProps.customerId)
            this.loadLocations(this.props.customerId);
    }

    loadLocations(customerId: number) {
        Api.getLocationData(customerId).then((res: Location) => {
            if (this.props.selectedLocation != null) {
                this.setSelected(this.props.selectedLocation, res);
            }

            this.setState({
                loading: false,
                fields: {
                    dataSource: [res],
                    id: 'id',
                    text: 'name',
                    child: 'subChild'
                }
            });
        });
    }

    setSelected(id: number, node: Location) {
        var that = this;

        if (node.id == id)
            node.selected = true;
        if (node.subChild != null && node.subChild.length > 0)
            _.each(node.subChild, function (sub) { that.setSelected(id, sub) });
    }

    render() {
        return (
            <div>
                {this.state.fields &&
                    <TreeViewComponent
                        id="treeview"
                        fields={this.state.fields}
                        sortOrder='Ascending'
                        nodeSelected={this.props.locationSelected}
                    />}
            </div>
        );
    }
}

export default LocationList;

export interface ILocationListProps {
    customerId: number;
    locationSelected: (ev: any) => void;
    selectedLocation?: number | null;
}

export interface ILocationListState {
    loading: boolean;
    fields: Object;
}