import { toast } from "react-toastify";
import _ from "lodash";
import { AxiosResponse } from "axios";

export class Logger {
    public static LogSuccess(msg: string) {
        toast.success(msg, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });
    };

    public static LogWarning(msg: string) {
        toast.warn(msg, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });
    };

    public static LogError(response: AxiosResponse) {
        var errors: string[] = [];
        _.each(response.data.errors, function (error: string) {
            errors.push(error);
        });

        Logger.LogErrorMessage(errors.join("\n"));
    };

    public static LogErrorMessage(message: string) {
        if (!message || message === "")
            message = "Something went wrong, please contact IT";
        toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });
    };
}