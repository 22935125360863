import React from "react";
import styles from "./qrcodes.module.scss";
import { AuthenticationService } from "../../authenticationService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Api } from "../../Api";

class QrCodes extends React.Component<IQRCodesProps, IQRCodesState> {
    private _authService: AuthenticationService;

    constructor(props: IQRCodesProps) {
        super(props);

        this._authService = props.authenticationService;

        this.state = {
            loading: false
        };

        this.onDownload = this.onDownload.bind(this);
    }

    componentDidMount() {
        //load data
    }

    onDownload() {
        this.setState({ loading: true });
        Api.downloadQR().then(resp => {
            this.setState({ loading: false });
        });
    }


    render() {
        return (
            <div className={styles.container}>
                {!this.state.loading && <button type="button" className="btn btn-success" onClick={this.onDownload}><FontAwesomeIcon icon="download" /> Download 10 codes</button>}
            </div>
        );
    }
}

export default QrCodes;

export interface IQRCodesProps {
    authenticationService: AuthenticationService;
}

export interface IQRCodesState {
    loading: boolean;
}