import React from "react";
import { Device } from "../../models";
import styles from "./styles.module.scss";
import ReactModal from "react-modal";
import { Api } from "../../Api";
import { Logger } from "../../Logger";

class CustomerEditNameDialog extends React.Component<IcustomerEditNameDialogProps, IcustomerEditNameDialogState> {
    constructor(props: IcustomerEditNameDialogProps) {
        super(props);

        this.state = {
            loading: false,
            name: props.name
        };

        this.onSaveCustomer = this.onSaveCustomer.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
    }

    onSaveCustomer() {
        var that = this;
        this.setState({
            loading: true
        });
        Api.updateName(this.props.id, this.state.name).then(res => {
            Logger.LogSuccess("Klant aangepast");
            that.setState({
                loading: false,
            });
            that.props.modalClosed(this.state.name);
        });
    }

    onChangeName(e: React.FormEvent<HTMLInputElement>) {
        if (e.currentTarget.value && e.currentTarget.value.length <= 200)
            this.setState({ name: e.currentTarget.value });
    }

    render() {
        return (
            <ReactModal isOpen={true} className={styles.confirmModal} overlayClassName={styles.overlayConfirmModal} ariaHideApp={false}>
                <label>Naam: </label><input type="text" className="form-control" value={this.state.name} onChange={this.onChangeName} />

                <div className={styles.buttonContainer}>
                    <button className={[styles.button, 'btn', 'btn-success'].join(' ')} onClick={this.onSaveCustomer}>Opslaan</button>
                    <button className={[styles.button, 'btn', 'btn-danger'].join(' ')} onClick={() => this.props.modalClosed(this.props.name)}>Sluiten</button>
                </div>
            </ReactModal>
        );
    }
}

export default CustomerEditNameDialog;

export interface IcustomerEditNameDialogProps {
    modalClosed: (name: string) => void;
    name: string;
    id: number;
}

export interface IcustomerEditNameDialogState {
    loading: boolean;
    name: string;
}